import React from "react";
import styles from "./ui/index.module.css";
import img from "../../../assets/png/pricingSystem.jpeg";
import icon1 from "../../../assets/icons/wallet.png";
import icon2 from "../../../assets/icons/luggage.svg";
import icon3 from "../../../assets/icons/plane.svg";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Text } from "../../../shared/Text/Text";
import { Title } from "../../../shared/title/Title";

const PricingSystem = () => {
  return (
    <>
      <Title $fz="62px" className={styles.title}>
        pricing system
      </Title>

      <div className={` ${styles.container}`}>
        <div className={` ${styles.container_left}`}>
          <div className={` ${styles.container_left_top}`}>
            <div className={` ${styles.sub}  ${styles.content}`}>
              <div className={` ${styles.icon}`}>
                <img src={icon1} alt="icon" />
              </div>
              <div>
                <SecondTitle color="white" fz="22px" mb="20px">
                  You can use the community on a subscription basis
                </SecondTitle>
                <Text color="white">
                </Text>
              </div>
            </div>

            <div className={` ${styles.trial} ${styles.content}`}>
              <div className={` ${styles.icon}`}>
                <img src={icon2} alt="icon" />
              </div>
              <div>
                <SecondTitle fz="22px" mb="20px">
                  Try it for free for 7 days <br />
                  and then get $28 per year{" "}
                </SecondTitle>
                <Text>
                </Text>
              </div>
            </div>
          </div>
          <div className={` ${styles.lease}  ${styles.content}`}>
            <div className={` ${styles.icon}`}>
              <img src={icon3} alt="icon" />
            </div>
            <div>
              {" "}
              <SecondTitle fz="22px" mb="20px">
                Rent apartments based on the cost of long-term lease
              </SecondTitle>
              <Text>
              </Text>
            </div>
          </div>
        </div>
        <img src={img} alt="" className={` ${styles.img}`} />
      </div>
    </>
  );
};

export default PricingSystem;
