import { FC, useEffect } from "react";
import Hero from "../../entities/home/hero/Hero";
import Community from "../../entities/home/community/Community";
import Recoll from "../../entities/home/recoll/Recoll";
import HowItWork from "../../entities/home/howItWork/HowItWork";
import HomesInTheCommunity from "../../entities/home/homes/HomesInTheCommunity";
import Perks from "../../entities/home/perks/Perks";
import Travel from "../../entities/home/travel/Travel";
import Questions from "../../entities/questions/Questions";
import TravelWithFlatSharing from "../../entities/travelWithFlatSharing/TravelWithFlatSharing";
import { useModal } from "../../app/helpers/hooks/useModal";
import Login from "../../entities/auth/login/Login";
import { useSearchParams } from "react-router-dom";
const HomePage: FC = () => {
  const { isOpen, openModal, closeModal, ModalComponent } = useModal();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const step = JSON.parse(searchParams.get("step") || "false");
    if (!token && step) {
      openModal();
    }
  }, []);

  return (
    <div>
      <Hero />
      <Community />
      <Recoll />
      <HowItWork />
      <HomesInTheCommunity />
      <Perks />
      <Travel />
      <Questions />
      <TravelWithFlatSharing />
      <ModalComponent>
        <Login isOpen={isOpen} openModal={openModal} closeModal={closeModal} />
      </ModalComponent>
    </div>
  );
};

export default HomePage;
