import styled from "styled-components";

export const LessorRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 110px;
  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0;
    margin-top: -50px;
    padding: 68px 0 18px;
    min-height: 0;
  }
`;
