import Navbar from "./entities/nav/desctop/Navbar";
import MainRoutes from "./app/routes/MainRoutes";
import Footer from "./entities/footer/Footer";
import { useLocation, useParams } from "react-router-dom";
import GoogleAnalytics from "./app/googleAnalytics/GoogleAnalytics";

const App = () => {
  const location = useLocation();

  const hideNavAndFooter =
    location.pathname === "/registration" ||
    location.pathname.startsWith("/password-recovery");

  return (
    <div className="app">
      <GoogleAnalytics />
      {!hideNavAndFooter && <Navbar />}
      <MainRoutes />
      {!hideNavAndFooter && <Footer />}
    </div>
  );
};

export default App;
