import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import { CarouselProps } from "./model/types";
import arrow from "../../assets/icons/arrow_white.png";

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: white;
`;

const CarouselInner = styled.div<{ translate: number }>`
  display: flex;
  transition: transform 0.5s ease;

  gap: 30px;
  max-width: 1360px;
  min-width: 220px;
  margin: 0 auto;
  width: 92%;
  gap: 24px;
  transform: translateX(${(props) => props.translate}px);
  margin-left: 7%;
`;

const CarouselItem = styled.div<{ itemWidth: number }>`
  flex: 0 0 auto;
  width: ${({ itemWidth }) => itemWidth}px;
  box-sizing: border-box;
`;

const NavigationButton = styled.button<{ left?: boolean }>`
  position: absolute;
  top: 50%;

  ${(props) => (props.left ? "left: 7%;" : "right: 7%;")}

  transform: translateY(-50%);
  background-color: #282828;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  border: none;
  cursor: pointer;
`;

interface DynamicCarouselProps extends CarouselProps {
  itemWidth: { [key: number]: number };
}

const Carousel: FC<DynamicCarouselProps> = ({ children, itemWidth }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [translate, setTranslate] = useState(0);
  const [currentItemWidth, setCurrentItemWidth] = useState(getItemWidth());
  const [isLastItem, setIsLastItem] = useState(false);

  function getItemWidth() {
    const width = window.innerWidth;

    if (width >= 1000) return itemWidth[1000] || 300;
    if (width >= 768) return itemWidth[768] || 200;
    return itemWidth[0] || 150;
  }

  useEffect(() => {
    const updateItemWidth = () => setCurrentItemWidth(getItemWidth());

    updateItemWidth();
    window.addEventListener("resize", updateItemWidth);
    return () => window.removeEventListener("resize", updateItemWidth);
  }, [itemWidth]);

  const itemsCount = React.Children.count(children);
  const gap = 30;

  const goToNext = () => {
    if (currentIndex < itemsCount - 1) {
      setCurrentIndex((prev) => prev + 1);
      setTranslate((prev) => prev - (currentItemWidth + gap));
      setIsLastItem(currentIndex === itemsCount - 2);
    }
  };

  const goToPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
      setTranslate((prev) => prev + (currentItemWidth + gap));
      setIsLastItem(false);
    }
  };

  return (
    <CarouselWrapper>
      <CarouselInner translate={translate}>
        {React.Children.map(children, (child, index) => (
          <CarouselItem key={index} itemWidth={currentItemWidth}>
            {child}
          </CarouselItem>
        ))}
      </CarouselInner>
      {currentIndex > 0 && (
        <NavigationButton onClick={goToPrev} left>
          <img
            src={arrow}
            alt="Previous"
            style={{ transform: "rotate(180deg)" }}
          />
        </NavigationButton>
      )}
      {!isLastItem && (
        <NavigationButton onClick={goToNext}>
          <img src={arrow} alt="Next" />
        </NavigationButton>
      )}
    </CarouselWrapper>
  );
};

export default Carousel;
