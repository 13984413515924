import styled from "styled-components";
import { TextProps } from "./model/types";

export const Text = styled.p<TextProps>`
  font-family: "Roboto", sans-serif;
  font-weight: ${(props) => (props.fw ? props.fw : "400")};
  font-size: ${(props) => (props.fz ? props.fz : "16px")};
  line-height: 22px;
  width: ${(props) => (props.width ? props.width : "")};
  align-self: flex-start;
  color: ${(props) => (props.color ? props.color : "#282828")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "")};
  margin-top: ${(props) => (props.mt ? props.mt : "")};
  text-decoration: ${(props) => (props.$through ? "line-through" : "none")};

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
