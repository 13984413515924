import React from "react";
import styles from "./ui/index.module.css";

import StepContent from "../../../entities/howItWorks/howItWorksStep/StepContent";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import Questions from "../../../entities/questions/Questions";

import step1 from "../../../assets/png/howitworks/step11.jpeg";
import step2 from "../../../assets/png/howitworks/step22.jpeg";
import step3 from "../../../assets/png/howitworks/step33.jpeg";
import step4 from "../../../assets/png/howitworks/step44.jpeg";
import step5 from "../../../assets/png/howitworks/55.jpeg";

import TravelWithFlatSharing from "../../../entities/travelWithFlatSharing/TravelWithFlatSharing";
import PricingSystem from "../../../entities/howItWorks/pricingSystem/PricingSystem";
import WhatDoesItMean from "../../../entities/howItWorks/whatDoesItMean/WhatDoesItMean";
import { Title } from "../../../shared/title/Title";

const HowItWorksAuth = () => {
  return (
    <div>
      <div className="container">
        <div className={` ${styles.grey_header}`}>
          <div className={` ${styles.grey_wrap}`}>
            <SecondTitle fz="20px" weight="500">
              HOW IT WORKS
            </SecondTitle>
          </div>
        </div>
        <Title className={styles.title} $fz="62px" $lh="64px">
          How do rent <br /> an apartment?
        </Title>

        <StepContent
          step="1"
          stepTitle="Fill in the information about yourself in the My Profile section, specify the contacts that are convenient for you to contact"
          stepInfo="Tell us about yourself so that tenants can learn more about you already at the stage of viewing the apartment online. Tenants will be able to contact you using the specified contacts."
          img={step1}
        />
        <StepContent
          step="2"
          stepTitle="Add an advert"
          stepInfo="Create and place an advert using the specified form. You can view, edit or delete your posted ads in the My account section."
          buttonText="Add an advert"
          buttonLink="/add_apartmants"
          img={step2}
          reverse={true}
        />
        <StepContent
          step="3"
          stepTitle="Check out the suggestions in the Browse apartments section"
          stepInfo="Choose a convenient accommodation for you in a convenient area and on the right dates."
          img={step3}
        />
        <StepContent
          step="4"
          stepTitle="Contact the host in a way that is convenient for you"
          stepInfo="Check out the hosts contacts, contact them in one of the suggested ways and talk out the details of your stay."
          img={step5}
          reverse={true}
        />
        <PricingSystem />
        <WhatDoesItMean />
      </div>
      <Questions />
      <TravelWithFlatSharing />
    </div>
  );
};

export default HowItWorksAuth;
