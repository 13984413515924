import clsx from "./ui/index.module.css";
import { Logo } from "../../shared/logo/Logo";
import { Linked } from "../../shared/Linked/Linked";

import { ReactComponent as FaceBook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as Telegram } from "../../assets/icons/telegram.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer
      className={`${clsx.footer} container`}
      style={{ marginTop: "20px" }}
    >
      <div>
        <Logo onClick={() => navigate("/")}>
          <span>flat</span>
          <span>sharing</span>
        </Logo>
      </div>
      <ul className="hide">
        <Linked onClick={() => navigate("/browse")} $size="18px">
          Browse apartments
        </Linked>
        <Linked
          target="_blank"
          href="https://docs.google.com/document/d/12LqbtKN_8tjH5Sij9OWektgSvhf98OvBXLckIdrztyo/edit"
          $size="18px"
        >
          Terms of Service
        </Linked>
        <Linked
          target="_blank"
          href="https://docs.google.com/document/d/18DGYWqlOGvbP9XJQdNaqohwDd9SLZ48q5rSw03EjVOU/edit"
          $size="18px"
        >
          Privacy Policy
        </Linked>
        <Linked
          onClick={() => {
            navigate("/how_it_works");
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          $size="18px"
        >
          How it works
        </Linked>
        <Linked
          onClick={() => {
            navigate("/blog");
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          $size="18px"
        >
          Blog
        </Linked>
      </ul>
      <div className="hide">
        <FaceBook />
        <Instagram />
        <Telegram />
      </div>
    </footer>
  );
};

export default Footer;
