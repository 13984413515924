import clsx from "./ui/index.module.css";
import { Title } from "../../../shared/title/Title";
import { Text } from "../../../shared/Text/Text";
import { GreyCard } from "../../../shared/greyCard/GreyCard";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Button } from "../../../shared/button/Button";
import { useNavigate } from "react-router-dom";

const Perks = () => {
  const navigate = useNavigate();
  return (
    <div id="margin_top" className={`container ${clsx.perks_wrapper}`}>
      <div className={clsx.titles}>
        <Title>Community perks</Title>
        <div className={clsx.text}>
          <Text>
          Chat with like-minded people in the community and learn all about immigration and travel to other countries
          </Text>
        </div>
      </div>
      <div className={clsx.card}>
        <GreyCard
          style={{ alignItems: "flex-start", justifyContent: "space-between" }}
          id={clsx.grey_card}
        >
          <div className={clsx.icon}>
            <img src="../perks/perks_1.jpg" alt="" />
          </div>
          <div className={clsx.second_title}>
            <SecondTitle>Meet for random coffee</SecondTitle>
            <Text>
            A special service where you can see who else from the community is in your city and meet for a cup of coffee
            </Text>
          </div>
        </GreyCard>
        <GreyCard
          style={{ alignItems: "flex-start", justifyContent: "space-between" }}
          id={clsx.grey_card}
        >
          <div className={clsx.icon}>
            <img src="../perks/perks_2.jpg" alt="" />
          </div>
          <div className={clsx.second_title}>
            <SecondTitle>Information on visa processing, residence permits, and taxes</SecondTitle>
            <Text>
             We gather information and share it with you to make traveling even easier
            </Text>
          </div>
        </GreyCard>
        <GreyCard
          style={{ alignItems: "flex-start", justifyContent: "space-between" }}
          id={clsx.grey_card}
        >
          <div className={clsx.icon}>
            <img src="../perks/perks_3.jpg" alt="" />
          </div>
          <div className={clsx.second_title}>
            <SecondTitle>Online calls to discuss travel and immigration experiences</SecondTitle>
            <Text>
            We share emotions, experiences, and travel recommendations with each other
            </Text>
          </div>
        </GreyCard>
      </div>
      <Button
        onClick={() => navigate("/registration")}
        style={{ marginTop: "40px" }}
        $bg
        $icon
      >
        Join the community
      </Button>
    </div>
  );
};

export default Perks;
