import styled from "styled-components";
import { LinkedProps } from "./model/types";

export const Linked = styled.a<LinkedProps>`
  font-weight: ${(props) => (props.$weight ? props.$weight : "normal")};
  font-size: ${(props) => (props.$size ? props.$size : "16px")};
  text-decoration: ${(props) => (props.$underline ? "underline" : "none")};
  color: #282828;
  line-height: 21px;
  cursor: pointer;
`;
