import React from "react";
import StepContent from "../../../entities/howItWorks/howItWorksStep/StepContent";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import Questions from "../../../entities/questions/Questions";

import styles from "./ui/index.module.css";

import step1 from "../../../assets/png/howitworks/step1.jpeg";
import step2 from "../../../assets/png/howitworks/step2.jpeg";
import step3 from "../../../assets/png/howitworks/step3.jpeg";
import step4 from "../../../assets/png/howitworks/step4.jpeg";
import TravelWithFlatSharing from "../../../entities/travelWithFlatSharing/TravelWithFlatSharing";
import PricingSystem from "../../../entities/howItWorks/pricingSystem/PricingSystem";
import WhatDoesItMean from "../../../entities/howItWorks/whatDoesItMean/WhatDoesItMean";
import { Title } from "../../../shared/title/Title";

const HowItWorksNotAuth = () => {
  return (
    <div>
      <div className="container">
        <div className={` ${styles.grey_header}`}>
          <div className={` ${styles.grey_wrap}`}>
            <SecondTitle fz="20px" weight="500">
              HOW IT WORKS
            </SecondTitle>
          </div>
        </div>
        <Title className={styles.title} $fz="62px" $lh="64px">
          How do I join <br /> the community?
        </Title>

        <StepContent
          step="1"
          stepTitle="Submit an Application to Join the Community"
          stepInfo="Fill out a simple application form and submit it. This will take just 5 minutes."
          img={step1}
        />
        <StepContent
          step="2"
          stepTitle="We Will Respond Within a Day"
          stepInfo="Our team will review your application and get back to you within 24 hours."
          img={step2}
          reverse={true}
        />
        <StepContent
          step="3"
          stepTitle="Move into Affordable Options or List Your Own Property"
          stepInfo="Choose from affordable housing options listed by the price of long-term rent or list your own property."
          img={step3}
        />
        <StepContent
          step="4"
          stepTitle="Save Up to $4,000 a Year"
          stepInfo="Enjoy being part of the community and save significant amounts annually."
          img={step4}
          reverse={true}
          buttonLink="/registration"
          buttonText="Join the community"
        />
        <PricingSystem />
        <WhatDoesItMean />
      </div>
      <Questions />
      <TravelWithFlatSharing />
    </div>
  );
};

export default HowItWorksNotAuth;
