import style from "./ui/index.module.css";
import { Title } from "../../../shared/title/Title";
import { Text } from "../../../shared/Text/Text";
import arrow from "../../../assets/icons/arraowButton.svg";
import city1 from "../../../assets/png/browse/London.png";
import city2 from "../../../assets/png/browse/Nyc.png";
import city3 from "../../../assets/png/browse/Houston.png";
import city4 from "../../../assets/png/browse/Madrid.png";
import city5 from "../../../assets/png/browse/Atlanta.png";
import city6 from "../../../assets/png/browse/Berlin.png";

const PopularDestination = () => {
  return (
    <div id="margin_top" className={`${style.wrapper} container`}>
      <Title>Popular destinations</Title>
      <Text>
      </Text>
      <div className={style.destinations}>
        <div className={style.destination}>
          <img className={style.destination_img} src={city1} alt="London" />
          <div className={style.icon}>
            <img src={arrow} alt="arrow icon" />
          </div>
          <Text>Apartments in London</Text>
        </div>
        <div className={style.destination}>
          <img className={style.destination_img} src={city2} alt="New York" />
          <div className={style.icon}>
            <img src={arrow} alt="arrow icon" />
          </div>
          <Text>Apartments in New York</Text>
        </div>
        <div className={style.destination}>
          <img className={style.destination_img} src={city3} alt="Houston" />
          <div className={style.icon}>
            <img src={arrow} alt="arrow icon" />
          </div>
          <Text>Apartments in Houston</Text>
        </div>
        <div className={style.destination}>
          <img className={style.destination_img} src={city4} alt="Madrid" />
          <div className={style.icon}>
            <img src={arrow} alt="arrow icon" />
          </div>
          <Text>Apartments in Madrid</Text>
        </div>
        <div className={style.destination}>
          <img className={style.destination_img} src={city5} alt="Atlanta" />
          <div className={style.icon}>
            <img src={arrow} alt="arrow icon" />
          </div>
          <Text>Apartments in Atlanta</Text>
        </div>
        <div className={style.destination}>
          <img className={style.destination_img} src={city1} alt="Berlin" />
          <div className={style.icon}>
            <img src={arrow} alt="arrow icon" />
          </div>
          <Text>Apartments in Berlin</Text>
        </div>
      </div>
    </div>
  );
};

export default PopularDestination;
