import styles from "../ui/index.module.css";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import {
  useGetUserEmailQuery,
  useGetUserQuery,
  useLogoutMutation,
} from "../../../app/redux/auth/authApi";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../shared/button/Button";
import { useModal } from "../../../app/helpers/hooks/useModal";
import Login, { AcceptedLogIn } from "../../auth/login/Login";
import { useEffect, useState } from "react";

const Sidebar = () => {
  const { data } = useGetUserQuery({});
  const { data: userResponse, refetch } = useGetUserEmailQuery({});
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, openModal, closeModal, ModalComponent } = useModal();
  const [tokenState, setTokenState] = useState<string | null>(null);

  const verified = JSON.parse(localStorage.getItem("verified") as string);

  const token = localStorage.getItem("token");

  const step = localStorage.getItem("step");

  const handleLogout = async () => {
    try {
      await logout("");
      localStorage.removeItem("token");
      navigate("/");
      window.location.reload();
    } catch (error) {}
  };

  const handleGetToken = () => {
    const token = localStorage.getItem("token");

    setTokenState(token);
    return tokenState;
  };

  useEffect(() => {
    handleGetToken();
  }, []);

  return (
    <div
      className={`${styles.left_side} ${
        location.pathname == "/account/myads" ||
        location.pathname == "/account/myarchivedads" ||
        location.pathname == "/account/myprofile" ||
        location.pathname == "/account/subscription"
          ? styles.hide_list
          : null
      } `}
    >
      <div className={styles.user_block}>
        <div className={styles.user_avatar}>
          <img
            src={
              data?.picture_url
                ? data.picture_url
                : "https://i.pinimg.com/564x/25/ee/de/25eedef494e9b4ce02b14990c9b5db2d.jpg"
            }
            alt="user avatar"
          />
          <span onClick={() => navigate("/account/myprofile")}>...</span>
        </div>
        <SecondTitle weight="400" fz="20px" style={{ minHeight: "24px" }}>
          {data?.full_name || ""}
        </SecondTitle>
      </div>
      <ul className={styles.list}>
        <>
          <li
            onClick={() => {
              if (token) {
                refetch();
                if (userResponse?.is_accepted && userResponse?.is_verified) {
                  navigate("/account/myads");
                } else if (!userResponse?.is_verified) {
                  navigate("/registration?step=2");
                } else if (!userResponse.is_accepted) {
                  navigate(`/registration?step=${step ? step : "1"}`);
                }
              } else {
                openModal();
              }
            }}
            className={
              location.pathname == "/account/myads" ||
              location.pathname == "/account/profile"
                ? styles.active_li
                : location.pathname == "/account/myarchivedads"
                ? styles.active_li
                : styles.inactive_li
            }
          >
            My Ads
          </li>

          <li
            onClick={() => {
              navigate("/account/myprofile");
            }}
            className={
              location.pathname == "/account/myprofile"
                ? styles.active_li
                : styles.inactive_li
            }
          >
            My Profile
          </li>
          <li
            onClick={() => {
              if (token) {
                refetch();

                if (userResponse?.is_accepted && userResponse?.is_verified) {
                  navigate("/account/subscription");
                } else if (!userResponse?.is_verified) {
                  navigate("/registration?step=2");
                } else if (!userResponse.is_accepted) {
                  navigate(`/registration?step=${step ? step : "1"}`);
                }
              } else {
                openModal();
              }
            }}
            className={
              location.pathname == "/account/subscription"
                ? styles.active_li
                : styles.inactive_li
            }
          >
            Subscription
          </li>
        </>
      </ul>
      <Button
        style={{
          paddingLeft: "0px",
          justifyContent: "left",
        }}
        $icon
        $iconBgColor="#F5F6F6"
        className={styles.logout_btn}
        onClick={handleLogout}
      >
        Log out of profile
      </Button>
      <ModalComponent>
        <Login isOpen={isOpen} openModal={openModal} closeModal={closeModal} />
      </ModalComponent>
    </div>
  );
};

export default Sidebar;
