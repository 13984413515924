import { useNavigate } from "react-router-dom";
import { Button } from "../../../shared/button/Button";
import { Text } from "../../../shared/Text/Text";
import { Title } from "../../../shared/title/Title";
import styles from "./ui/index.module.css";

import img1 from "../../../assets/png/whatdoesitmean/png1.jpeg";
import img2 from "../../../assets/png/whatdoesitmean/png2.jpeg";
import img3 from "../../../assets/png/whatdoesitmean/png3.jpeg";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";

const WhatDoesItMean = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className={styles.container_one}>
        <Title $fz="62px">What does it mean to be a part of FlatSharing?</Title>

        <div className={` ${styles.container_one_right}`}>
          <Text fw="500" fz="16px">
          </Text>
          <br />
          <br />
          <Text fz="16px" mb="40px">
          </Text>
          <Button
            $fz="24px"
            $bg
            $icon
            onClick={() => navigate("/registration")}
          >
            Join the community{" "}
          </Button>
        </div>
      </div>
      <div className={` ${styles.container_two}`}>
        <div className={` ${styles.card}`}>
          <img src={img1} alt="" />

          <div>
            <SecondTitle mb="16px" fz="26px">
              People in the community <br /> are checked
            </SecondTitle>
            <Text fz="16px">
              We accept only people from top universities, people who work in IT
              and people from creative industries.
            </Text>
          </div>
        </div>
        <div className={` ${styles.card}`}>
          <img src={img2} alt="" />
          <div>
            <SecondTitle mb="16px" fz="26px">
              Online calls <br /> and info sharing
            </SecondTitle>
            <Text fz="16px">
              We hold online calls and info sharing sessions to help you get to
              know travelling better.
            </Text>
          </div>
        </div>
        <div className={` ${styles.card}`}>
          <img src={img3} alt="" />
          <div>
            {" "}
            <SecondTitle mb="16px" fz="26px">
              Housing at the cost <br /> of long-term rent
            </SecondTitle>
            <Text fz="16px">
              We publish apartments at the cost of long-term rent.
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatDoesItMean;
