import { FC } from "react";
import { Button } from "../../shared/button/Button";
import { LessorAvatar } from "../../shared/lessor/LessorAvatar";
import { SecondTitle } from "../../shared/secondTitle/SecondTitle";
import { Text } from "../../shared/Text/Text";
import styles from "./ui/index.module.css";
import { ModalProps } from "../auth/login/model/types";
import closeIcon from "../../assets/icons/close.svg";

interface Props extends ModalProps {
  socialNetworks: {
    Instagram: string | null;
    LinkedIn: string | null;
    WhatsApp: string | null;
    Telegram: string | null;
  };
  data: any;
}

const ContactsPopUp: FC<Props> = ({ socialNetworks, data }) => {
  const handleButtonClick = (value: string, name: string) => {
    if (name == "Instagram") {
      window.open(`https://${value}`, "_blank");
    } else if (name == "Telegram") {
      window.open(`https://t.me/${value}`, "_blank");
    }
  };

  const renderButton = (name: string, value: string | null) => {
    if (!value) return null;
    return (
      <Button
        $icon
        $iconColor
        $iconBgColor="rgba(40, 40, 40, 1)"
        className={styles.social_btn}
        onClick={() => handleButtonClick(value, name)}
      >
        {name}
      </Button>
    );
  };

  return (
    <div className={styles.header_container}>
      <div className={styles.close_icon}>
        <img src={closeIcon} alt="close icon" />
      </div>
      <div className={styles.header}>
        <LessorAvatar
          className={styles.header_img}
          src={
            "https://i.pinimg.com/564x/25/ee/de/25eedef494e9b4ce02b14990c9b5db2d.jpg"
          }
        />
        <div>
          <SecondTitle>{data.data.full_name}</SecondTitle>
          <Text fw="600">JOB</Text>
          <Text>{data.data.description}</Text>
        </div>
      </div>
      <div className={styles.container_links}>
        <div>
          <SecondTitle mb="10" className={styles.links_btn} color="white">
            Social network
          </SecondTitle>
          <div className={styles.container_buttons}>
            {renderButton("Instagram", socialNetworks.Instagram)}
            {renderButton("LinkedIn", socialNetworks.LinkedIn)}
          </div>
        </div>

        <div>
          <SecondTitle className={styles.links_btn} color="white">
            Book an apartment
          </SecondTitle>
          <Text color="white" fw="400">
            Contact {data?.data?.full_name} in any way convenient for you. Lorem
            ipsum dolor sit amet, consectetur adipiscing elit.
          </Text>
          {/* <Text color="white" fw="500" mb="20px">
            E-mail: username@gmail.com
          </Text> */}
          <div className={styles.container_buttons}>
            {renderButton("WhatsApp", socialNetworks.WhatsApp)}
            {renderButton("Telegram", data.data.tg_username)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsPopUp;
