import { ChangeEvent, useEffect, useState } from "react";
import styles from "../ui/index.module.css";
import { Title } from "../../../shared/title/Title";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Input } from "../../../shared/input/Input";
import replaceIcon from "../../../assets/icons/button replace photo.svg";
import { TextArea } from "../../../shared/textArea/TextArea";
import { Text } from "../../../shared/Text/Text";
import { Button } from "../../../shared/button/Button";
import {
  useEditUserInfoMutation,
  useEditUserMutation,
  useGetUserEmailQuery,
  useGetUserQuery,
  useUpdateUserPictureMutation,
} from "../../../app/redux/auth/authApi";
import { useModal } from "../../../app/helpers/hooks/useModal";
import { FormDataTypes } from "./model/types";
import { useNavigate } from "react-router-dom";
import SocialMediaInputs from "../../../shared/socialMediaGroup/SocialMedia";
import backIcon from "../../../assets/icons/arrow_toLeft.svg";
import Login from "../../auth/login/Login";

const MyProfile = () => {
  const navigate = useNavigate();
  const { data: userInfo, isLoading } = useGetUserQuery({});
  const [edit] = useEditUserInfoMutation();
  const [editUser] = useEditUserMutation();
  const [imageUpdate] = useUpdateUserPictureMutation();

  const { isOpen, closeModal, openModal, ModalComponent } = useModal();

  const [formData, setFormData] = useState<FormDataTypes>({
    id: "",
    email: "",
    password: "",
    new_password: "",
    want_to_let: "",
    firstName: "",
    lastName: "",
    address: "",
    profession: "",
    instagram: "",
    linkedin: "",
    facebook: "",
    twitter: "",
    telegram: "",
    whatsapp: "",
    description: "",
  });
  const [imageFile, setImageFile] = useState<File | null>(null);

  const socialNetworks = {
    Instagram: userInfo?.instagram,
    LinkedIn: userInfo?.linkedin,
    WhatsApp: userInfo?.whatsapp,
    Telegram: userInfo?.tg_username,
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput.click();
  };

  const handleEdit = async () => {
    const editedData = {
      full_name: `${formData.firstName} ${formData.lastName}`,
      how_did_you_hear: "",
      tg_id: null,
      onboarding_step: null,
      onboarding_completed: false,
      tg_username: formData.telegram,
      whatsapp: formData.whatsapp,
      instagram: formData.instagram,
      linkedin: formData.linkedin,
      description: formData.profession,
      where_to_rent: formData.address,
      where_to_let: formData.want_to_let,
      meet: false,
      notifications: true,
      contact_email: null,
      blocked_timestamp: null,
      bot_name: "",
      user_id: formData.id,
    };
    const userEmail = {
      email: formData.email,
    };
    try {
      await edit({
        user: editedData,
      });
      await editUser({
        user: userEmail,
      });
      if (imageFile) {
        const form = new FormData();
        if (imageFile) {
          form.append("file", imageFile);
        }
        await imageUpdate({ data: form });
      }

      navigate("/account/myads");
      window.location.reload();
    } catch (error) {}
  };

  const selectFormData = () => {
    const fullName = userInfo?.full_name || "";
    const [firstName = "", lastName = ""] = fullName.split(" ");

    setFormData({
      // @ts-ignore
      id: userInfo?.user_id || "",
      email: userInfo?.email || "",
      password: "",
      new_password: "",
      want_to_let: userInfo?.where_to_let || "",
      firstName,
      lastName,
      address: userInfo?.where_to_rent || "",
      profession: "",
      instagram: userInfo?.instagram || "",
      linkedin: userInfo?.linkedin || "",
      facebook: "",
      twitter: "",
      telegram: userInfo?.tg_username || "",
      whatsapp: userInfo?.whatsapp || "",
      description: userInfo?.description || "",
    });
  };

  useEffect(() => {
    selectFormData();
  }, [userInfo]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className={styles.right_side}>
      <button
        className={styles.back}
        onClick={() => navigate("/account/profile")}
      >
        <div>
          <img src={backIcon} alt="arrow to back" />
        </div>{" "}
        Back
      </button>
      <Title $fz="50px" style={{ marginBottom: "50px" }}>
        My Profile
      </Title>
      <div className={styles.info_wrapper}>
        <div className={styles.personal_info}>
          <div className={styles.personal_info_text}>
            <SecondTitle fz="24px">Personal Information</SecondTitle>
            <Input
              name="firstName"
              onChange={handleChange}
              value={formData.firstName}
              placeholder="First Name"
            />
            <Input
              name="lastName"
              onChange={handleChange}
              value={formData.lastName}
              placeholder="Last Name"
            />
          </div>
          <div className={styles.personal_info_avatar}>
            <input
              type="file"
              accept="image/*"
              onChange={handleChange}
              style={{ display: "none" }}
              id="fileInput"
            />
            <img
              className={styles.user_img}
              src={
                imageFile
                  ? URL.createObjectURL(imageFile)
                  : // @ts-ignore
                  userInfo?.picture_url
                  ? // @ts-ignore
                    userInfo?.picture_url
                  : "https://i.pinimg.com/564x/25/ee/de/25eedef494e9b4ce02b14990c9b5db2d.jpg"
              }
              alt="user avatar"
            />
            <img
              onClick={handleClick}
              className={styles.user_img_icon}
              src={replaceIcon}
              alt="replase icon"
            />
          </div>
        </div>
        <div className={styles.personal_info_item}>
          <SecondTitle fz="24px">Desciption</SecondTitle>
          <Input
            name="profession"
            onChange={handleChange}
            // @ts-ignore
            value={userInfo?.description}
            placeholder="Enter your profession"
          />
        </div>
        <div className={styles.personal_info_item}>
          <SecondTitle fz="24px">
            Are you interested in participating in regular community forums
            dedicated to travel, visas, relocation and taxes?
          </SecondTitle>
          <div className={styles.radio}>
            <div>
              <input type="radio" name="want_to_let" value="Yes" checked />
              <p>Yes</p>
            </div>
            <div>
              <input type="radio" name="want_to_let" value="No" />
              <p>No</p>
            </div>
          </div>
        </div>
        <div className={styles.personal_info_item}>
          <SecondTitle fz="24px">Email</SecondTitle>
          <Input
            name="email"
            onChange={handleChange}
            value={formData.email}
            placeholder="Email"
          />
          <div className={styles.check}>
            <input type="checkbox" name="" id="chekc" />
            <label htmlFor="chekc">Do not show email in the community</label>
          </div>
        </div>
        <div className={styles.personal_info_item}>
          <SecondTitle fz="24px">Social network</SecondTitle>
          <Text style={{ marginBottom: "20px" }}>
            Increase the credibility of your profile by adding your social media
            pages.
          </Text>
          <SocialMediaInputs
            formData={formData}
            setFormData={setFormData}
            socialMediaArray={[
              "instagram",
              "linkedin",
              "facebook",
              "twitter",
              "telegram",
              "whatsapp",
            ]}
          />
        </div>
        <div className={styles.personal_info_item}>
          <SecondTitle fz="24px">Replace the password</SecondTitle>
          <Input placeholder="Current password" />
          <Input placeholder="New password" />
          <Input placeholder="Confirm the new password" />
          <div className={styles.check}>
            <input type="checkbox" name="" id="chekc" />
            <label htmlFor="chekc">Show password</label>
          </div>
          <Text
            onClick={() => openModal()}
            fw="500"
            style={{ marginTop: "20px", cursor: "pointer" }}
          >
            Forgot your passowrd ?
          </Text>
        </div>
        <Button $bg $icon type="button" onClick={handleEdit}>
          Save
        </Button>
        <Text fw="500" style={{ marginTop: "20px" }}>
          How my profile{" "}
          <span
            onClick={openModal}
            style={{
              textDecoration: "underline",
              fontWeight: "500",
              cursor: "pointer",
            }}
          >
            is seen by other community members
          </span>
        </Text>
      </div>

      <ModalComponent>
        <Login
          closeModal={closeModal}
          isOpen={isOpen}
          openModal={openModal}
          isForgot={true}
        />
      </ModalComponent>
    </div>
  );
};

export default MyProfile;
