import React from 'react';
import { Button } from '../../shared/button/Button';
import { Text } from '../../shared/Text/Text';
import { Title } from '../../shared/title/Title';
import notFoundStyles from "./ui/index.module.css"
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <div  className={`container ${notFoundStyles.notfound}`} >
            <Title>
              404
            </Title>
            <Text>
                This page could not be found.
            </Text>
            <Button onClick={() => navigate("/")} $icon $bg>
                Main page
            </Button>
        </div>
    );
};

export default NotFound;