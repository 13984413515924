import React from "react";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Text } from "../../../shared/Text/Text";
import { Title } from "../../../shared/title/Title";
import styles from "./ui/index.module.css";
import { StepProps } from "./model/types";
import { Button } from "../../../shared/button/Button";
import { useNavigate } from "react-router-dom";

const StepContent: React.FC<StepProps> = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (props.buttonLink) {
      navigate(props.buttonLink);
    }
  };
  return (
    <div
      className={` ${styles.container}`}
      style={{ flexDirection: props.reverse ? "row-reverse" : "row" }}
    >
      <div>
        <div className={` ${styles.steps_container}`}>
          <div className={styles.number}>0{props.step}</div>

          <div className={`${styles.text}`}>
            <SecondTitle fz="26px">{props.stepTitle}</SecondTitle>
            <Text>{props.stepInfo}</Text>
          </div>
        </div>
        {props.buttonText && props.buttonLink ? (
          <Button $fz="24px" $bg $icon onClick={handleClick}>
            {props.buttonText}
          </Button>
        ) : (
          <></>
        )}
      </div>

      <img src={props.img} alt="howitworkimg" className={`${styles.img}`} />
    </div>
  );
};

export default StepContent;
