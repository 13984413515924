import clsx from "./ui/index.module.css";
import { Title } from "../../../shared/title/Title";
import { Text } from "../../../shared/Text/Text";
import Carousel from "../../../features/carousel/Carousel";
import { Button } from "../../../shared/button/Button";
import { AddressGreyWrapper } from "../../../shared/addressGreyWrapperProps/AddressGreyWrapperProps";
import { AddressTitle } from "../../../shared/addressTitle/AddressTitle";
import { useNavigate } from "react-router-dom";

import london from "../../../assets/png/cities/london.webp";
import newyork from "../../../assets/png/cities/newyork.avif";
import paris from "../../../assets/png/cities/paris.webp";
import berlin from "../../../assets/png/cities/berlin.jpeg";
import barcelona from "../../../assets/png/cities/barcelona.webp";
import milan from "../../../assets/png/cities/milan.jpg";
import lisbon from "../../../assets/png/cities/lisbon.jpg";
import tbilisi from "../../../assets/png/cities/tbilisi.jpg";
import dubai from "../../../assets/png/cities/dubai.jpeg";
import yerevan from "../../../assets/png/cities/yerevan.jpg";

import lisbon_flat from "../../../assets/png/homepage/lisbon_flat.jpg";
import tbilisi_flat from "../../../assets/png/homepage/tbilisi_flat.jpg";
import amsterdam_flat from "../../../assets/png/homepage/amsterdam_flat.jpg";
import content from "./content.json";

const HomesInTheCommunity = () => {
  const itemWidth = {
    0: 80,
    768: 110,
    1000: 130,
  };
  const cities = [
    // { name: "All city", img: city1 },
    { name: "London", img: london },
    { name: "New York", img: newyork },
    { name: "Paris", img: paris },
    { name: "Berlin", img: berlin },
    { name: "Barcelona", img: barcelona },
    { name: "Milan", img: milan },
    { name: "Lisbon", img: lisbon },
    { name: "Dubai", img: dubai },
    { name: "Erevan", img: yerevan },
    { name: "Tbilisi", img: tbilisi },
  ];
  const images = [lisbon_flat, tbilisi_flat, amsterdam_flat];
  const navigate = useNavigate();

  return (
    <div id="margin_top" className={`${clsx.main_wrapper}`}>
      <div className={`container ${clsx.title_wrapper}`}>
        <Title>Homes in the community</Title>
        <Text id={clsx.item_text}></Text>
      </div>

      <div className={clsx.carousel}>
        <Carousel itemWidth={itemWidth}>
          {cities.map((item) => (
            <div className={clsx.card} key={item.name}>
              <img src={item.img} alt={`City ${item.name}`} />
              <Text style={{ alignSelf: "center" }}>{item.name}</Text>
            </div>
          ))}
        </Carousel>
      </div>

      <div className={`container`}>
        <div className={clsx.wrapper}>
          {content.map((item) => (
            <div
              key={item.id}
              className={clsx.accordion}
              style={{ backgroundImage: `url(${images[item.id - 1]})` }}
            >
              <AddressGreyWrapper className={clsx.address_wrapper}>
                <AddressTitle>{item.title}</AddressTitle>
                <Text $through>{item.airBnb}</Text>
                <Text> {item.price}</Text>
              </AddressGreyWrapper>
            </div>
          ))}
        </div>
        <Button onClick={() => navigate("/registration")} $bg $icon>
          Browse apartments
        </Button>
      </div>
    </div>
  );
};

export default HomesInTheCommunity;
