import { useState, useEffect } from "react";
import { EditAdvertEntities } from "../../entities/editAdvert";
import {
  useEditAdvertMutation,
  useGetOneFlatQuery,
  useListingPictureUpdateMutation,
} from "../../app/redux/product/apiProducts";
import { useNavigate, useParams } from "react-router-dom";
import { EditailbleDataType } from "./model/types";
import { assembleFlatData } from "../../app/helpers/functions";
import clsx from "./ui/index.module.css";
import { Button } from "../../shared/button/Button";
import { STATUS_LISTING } from "../../app/constants/enum";
import { useGetUserEmailQuery } from "../../app/redux/auth/authApi";

export const EditAdvert = () => {
  const { id } = useParams();
  const { data } = useGetOneFlatQuery({ id });

  const navigate = useNavigate();

  const [editedListing] = useEditAdvertMutation();
  const [listingPictureUpdate] = useListingPictureUpdateMutation();

  const { data: userResponse } = useGetUserEmailQuery({});

  const [images, setImages] = useState({
    status: data?.status,
    listing_id: data?.listing_id,
    picture_url: "",
  });
  const [formatData, setFormatData] = useState<EditailbleDataType>({
    description: "",
    date_from: "",
    date_to: "",
    country: "",
    city: "",
    price: "",
    comments: "",
    is_rented: false,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    message_ids: "",
    channel_id: 0,
    status: "created",
    district: "",
    swap: "",
    room: "",
    flexible_dates: true,
    listing_id: 0,
    user_id: "",
    used_listing_pictures: [],
  });

  useEffect(() => {
    if (data) {
      setFormatData({
        ...data,
      });
    }
  }, [data]);

  //   const convertFilesToUsedListingPictures = (
  //     files: File[]
  //   ): UsedListingPicture[] => {
  //     return files.map((file, index) => ({
  //       listing_id: 0,
  //       listing_picture_id: index,
  //       picture_url: URL.createObjectURL(file),
  //       status: "pending",
  //     }));
  //   };

  const onImageChanger = (picture: any) => {
    setImages((prevData) => ({ ...prevData, picture_url: picture }));
  };

  const handlegetRooms = (data: number) => {
    setFormatData((prevData) => ({
      ...prevData,
      room: data.toString(),
    }));
  };

  return (
    <div className={clsx.wrapper}>
      <EditAdvertEntities
        formatData={formatData}
        handlegetRooms={handlegetRooms}
        onImageChanger={onImageChanger}
        setFormatData={setFormatData}
      />
      <Button
        $bg
        $icon
        style={{ alignSelf: "flex-start" }}
        onClick={() => {
          const flatData = assembleFlatData(formatData);
          editedListing({ listing_id: id, data: flatData });
          listingPictureUpdate({
            listing_picture_id: data?.listing_id,
            images: images,
          });
        }}
      >
        Save
      </Button>
    </div>
  );
};
