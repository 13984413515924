import React, { ChangeEvent, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./ui/index.module.css";
import { Title } from "../../shared/title/Title";
import { Text } from "../../shared/Text/Text";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../../shared/button/Button";
import arrowDownBlack from "../../assets/icons/arrows/arrow-down-black.svg";
import filterIcon from "../../assets/png/filter.png";
import closeIcon from "../../assets/icons/close.svg";
import { Logo } from "../../shared/logo/Logo";
import dayjs from "dayjs";

const BrowseApartments = () => {
  const [direction, setDirection] = useState<string>("");
  const [checkInDate, setCheckInDate] = useState<Date | null>(null);
  const [checkOutDate, setCheckOutDate] = useState<Date | null>(null);
  const [sorting, setSorting] = useState("upcoming_date");

  const [_, setSearchParams] = useSearchParams();

  const [burgerOpen, setBurgerOpen] = useState(false);

  const handleClick = () => {
    setBurgerOpen(!burgerOpen);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (burgerOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [burgerOpen]);

  const navigate = useNavigate();

  const handleSearch = () => {
    setSearchParams({
      page: "1",
      direction: direction || "",
      check_in_date:
        dayjs(checkInDate).format("YYYY-MM-DD") !== "Invalid Date"
          ? dayjs(checkInDate).format("YYYY-MM-DD")
          : "",
      check_out_date:
        dayjs(checkOutDate).format("YYYY-MM-DD") !== "Invalid Date"
          ? dayjs(checkOutDate).format("YYYY-MM-DD")
          : "",

      sorting:
        sorting === "New to old"
          ? "desc"
          : sorting === "Old to new"
          ? "asc"
          : "",
    });
    setBurgerOpen(false);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <div>
      <div className={`${styles.container} container`}>
        <Title>Browse Apartments</Title>
        <div className={styles.filters}>
          <div className={`${styles.filter} ${styles.direction_input}`}>
            <label>Direction</label>
            <input
              type="text"
              placeholder="Where do you want to go?"
              value={direction}
              onChange={(e) => setDirection(e.target.value)}
            />
            <div className={styles.arrow_icon}>
              <img src={arrowDownBlack} alt="arrow" />
            </div>
          </div>
          <div className={`${styles.filter} ${styles.date_input}`}>
            <label>Check-in</label>
            <DatePicker
              selected={checkInDate}
              onChange={(date) => setCheckInDate(date)}
              placeholderText="Select a date"
              className={styles.datePicker}
            />
            <div className={styles.arrow_icon}>
              <img src={arrowDownBlack} alt="arrow" />
            </div>
          </div>
          <div className={`${styles.filter} ${styles.date_input}`}>
            <label>Check-out</label>
            <DatePicker
              selected={checkOutDate}
              onChange={(date) => setCheckOutDate(date)}
              placeholderText="Select a date"
              className={styles.datePicker}
            />
            <div className={styles.arrow_icon}>
              <img src={arrowDownBlack} alt="arrow" />
            </div>
          </div>
          <div className={`${styles.filter} ${styles.select_input}`}>
            <label>Sorting</label>
            <select
              value={sorting}
              onChange={(e) => setSorting(e.target.value)}
            >
              <option value="Upcoming dates">Upcoming dates</option>
              <option value="New to old">New to old</option>
              <option value="Old to new">Old to new</option>
              <option value="Rating">Rating</option>
            </select>
            <div className={styles.arrow_icon}>
              <img src={arrowDownBlack} alt="arrow" />
            </div>
          </div>
          <Button
            $bg
            $icon
            style={{ maxHeight: "64px", alignSelf: "flex-end" }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BrowseApartments;
