import { FC, useState } from "react";
import arrowIncrease from "../../assets/icons/arrow_increase.svg";
import arrowReduce from "../../assets/icons/arrow-reduce.svg";
import arrowToLeft from "../../assets/icons/arrow_toLeft.svg";
import arrowToRight from "../../assets/icons/arrow_toRight.svg";
import styles from "./ui/index.module.css";

import { useModal } from "../../app/helpers/hooks/useModal";

interface oneImg {
  created_at: string;
  listing_id: number;
  listing_picture_id: number;
  picture_url: string;
  status: string;
  updated_at: string;
}

interface ApartmentDetailProps {
  images: oneImg[];
}

const ApartmentDetails: FC<ApartmentDetailProps> = ({ images }) => {
  const [hover, setHover] = useState(false);
  const [curr, setCurr] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const { isOpen, openModal, closeModal, ModalComponent } = useModal();

  const prev = () => {
    setCurr((curr: any) => (curr === 0 ? images.length - 1 : curr - 1));
  };

  const next = () => {
    setCurr((curr: any) => (curr === images.length - 1 ? 0 : curr + 1));
  };

  const handleImageClick = (index: number) => {
    setCurr(index);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50) {
      next();
    }
    if (touchStart - touchEnd < -50) {
      prev();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <img
          src={images[curr]?.picture_url}
          alt="Selected"
          className={styles.selected}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onClick={openModal}
          style={{ width: "100%", borderRadius: "10px" }}
        />

        {hover && (
          <>
            <div
              onMouseEnter={() => setHover(true)}
              onClick={openModal}
              className={`${styles.arrow_increase} ${styles.arrow}`}
            >
              <img src={arrowIncrease} alt="increase" />
            </div>
            <div className={styles.arrow_switches}>
              <div
                onMouseEnter={() => setHover(true)}
                onClick={prev}
                className={`${styles.arrow_switch} ${styles.arrow}`}
              >
                <img src={arrowToLeft} alt="increase" />
              </div>
              <div
                onMouseEnter={() => setHover(true)}
                onClick={next}
                className={`${styles.arrow_switch} ${styles.arrow}`}
              >
                <img src={arrowToRight} alt="increase" />
              </div>
            </div>
          </>
        )}
        <div className={styles.dots}>
          {images.map((item, index) => (
            <div
              key={item.listing_picture_id}
              style={index === curr ? { backgroundColor: "white" } : {}}
              className={styles.dot}
            ></div>
          ))}
        </div>
      </div>
      <div className={styles.images}>
        {images.slice(0, 4).map((image, index) => (
          <img
            key={index}
            src={image?.picture_url}
            alt={`Thumbnail ${index}`}
            onClick={() => handleImageClick(index)}
            className={styles.not_selected}
          />
        ))}
      </div>
      <ModalComponent>
        <div
          className={`${styles.modal} ${isOpen ? styles.active : null}`}
          onClick={closeModal}
        >
          <div className={styles.modal__item}>
            <img src={images[curr]?.picture_url} alt="" />
            <div className={styles.dots}>
              {images.map((item, index) => (
                <div
                  key={item.listing_picture_id}
                  style={index === curr ? { backgroundColor: "white" } : {}}
                  className={styles.dot}
                ></div>
              ))}
            </div>
          </div>
          <div
            onClick={closeModal}
            className={`${styles.arrow_increase_modal} ${styles.arrow}`}
          >
            <img src={arrowReduce} alt="increase" />
          </div>
          <div
            onClick={(e) => e.stopPropagation()}
            className={`${styles.arrow_switches} ${styles.switches_modal}`}
          >
            <div
              onMouseEnter={() => setHover(true)}
              onClick={prev}
              className={`${styles.arrow_switch} ${styles.arrow}`}
            >
              <img src={arrowToLeft} alt="increase" />
            </div>
            <div
              onMouseEnter={() => setHover(true)}
              onClick={next}
              className={`${styles.arrow_switch} ${styles.arrow}`}
            >
              <img src={arrowToRight} alt="increase" />
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default ApartmentDetails;
