import styles from "../ui/index.module.css";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { Text } from "../../../shared/Text/Text";
import MyAdsList from "./list/MyAdsList";
import { useGetUsersProductsQuery } from "../../../app/redux/product/apiProducts";
import { useLocation, useNavigate } from "react-router-dom";
import { STATUS_LISTING } from "../../../app/constants/enum";
import arrowDownBlack from "../../../assets/icons/arrows/arrow-down-black.svg";
import arrowDownWhite from "../../../assets/icons/arrows/arrow-down-white.svg";
import { Title } from "../../../shared/title/Title";
import backIcon from "../../../assets/icons/arrow_toLeft.svg";

const MyAds = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [checkInDate, setCheckInDate] = useState<Date | null>(null);
  const [checkOutDate, setCheckOutDate] = useState<Date | null>(null);

  const { data: currentAds } = useGetUsersProductsQuery({
    statuses: [
      STATUS_LISTING.POSTED,
      STATUS_LISTING.CREATE,
      STATUS_LISTING.FINISHED,
      STATUS_LISTING.UPDATED,
    ],
  });
  const { data: archivedAds } = useGetUsersProductsQuery({
    statuses: [STATUS_LISTING.ARCHIVED],
  });

  return (
    <div
      className={`${styles.right_side} ${
        location.pathname == "/account/profile" ? styles.hide_list : null
      }`}
    >
      <button
        className={styles.back}
        onClick={() => navigate("/account/profile")}
      >
        <div>
          <img src={backIcon} alt="arrow to back" />
        </div>{" "}
        Back
      </button>
      <Title className={styles.myads_title}>My ads</Title>
      <div className={styles.ads_header}>
        <SecondTitle
          onClick={() => navigate("/account/myads")}
          className={
            location.pathname == "/account/myads" ||
            location.pathname == "/account/profile"
              ? styles.active_link
              : styles.inactive_link
          }
          fz="24px"
        >
          Current Ads ({currentAds?.length})
        </SecondTitle>
        <SecondTitle
          onClick={() => navigate("/account/myarchivedads")}
          className={
            location.pathname == "/account/myarchivedads"
              ? styles.active_link
              : styles.inactive_link
          }
          fz="24px"
        >
          Archive Ads ({archivedAds?.length})
        </SecondTitle>
      </div>
      <div className={styles.ads_filter}>
        <div className={styles.filters}>
          <div className={styles.filter}>
            <label>Check-in</label>
            <DatePicker
              selected={checkInDate}
              onChange={(date) => setCheckInDate(date)}
              placeholderText="Select a date"
              className={styles.datePicker}
            />
            <div className={styles.arrow_icon}>
              <img src={arrowDownBlack} alt="arrow" />
            </div>
          </div>
          <div className={styles.filter}>
            <label>Check-out</label>
            <DatePicker
              selected={checkOutDate}
              onChange={(date) => setCheckOutDate(date)}
              placeholderText="Select a date"
              className={styles.datePicker}
            />
            <div className={styles.arrow_icon}>
              <img src={arrowDownBlack} alt="arrow" />
            </div>
          </div>
        </div>

        <div className={styles.search_btn_block}>
          <div className={styles.btn_text}>
            <Text className={styles.btn_text_sort}>Sorting</Text>
            <Text className={styles.btn_text_date} fz="20px">
              Upcoming dates
            </Text>
          </div>
          <div className={styles.search_btn}>
            <img src={arrowDownWhite} alt="arrow" />
          </div>
        </div>
      </div>

      {location.pathname == "/account/myads" ? (
        <MyAdsList data={currentAds} />
      ) : (
        <MyAdsList data={archivedAds} />
      )}
    </div>
  );
};

export default MyAds;
