import { FC } from "react";
import styles from "./ui/index.module.css";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Text } from "../../../shared/Text/Text";
import SocialMediaInputs from "../../../shared/socialMediaGroup/SocialMedia";
import { useSearchParams } from "react-router-dom";
import {
  useGetUserEmailQuery,
  usePaymentMutation,
} from "../../../app/redux/auth/authApi";
import { Button } from "../../../shared/button/Button";

interface StepProps {
  formData: any;
  setFormData: (data: any) => void;
}

// @ts-ignore
const SocialMedia: FC<StepProps> = ({ formData, setFormData }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const payment = searchParams.get("payment");

  const [paymentRequest] = usePaymentMutation();
  const { data: userInfo } = useGetUserEmailQuery({});

  const handlePayment = async () => {
    const data = {
      amount: 28,
      description: `Access to FlatSharing Community for 1 year for ${userInfo?.email} ${userInfo?.id}`,
      recurring_period: "year",
      trial_days: 7,
      quantity: 1,
      user_id: userInfo?.id,
      period: 366,
    };
    const { data: paymentResponse } = await paymentRequest({ data: data });
    if (paymentResponse) {
      setSearchParams({
        step: (Number(searchParams.get("step")) - 1).toString(),
      });

      window.open(paymentResponse.stripe_link, "_blank");
    }
  };

  return (
    <div className={`${styles.step2_wrapper} ${styles.social_wrapper}`}>
      <div className={styles.step1_titles}>
        <SecondTitle className={styles.social_title}>
          You have activated access!
        </SecondTitle>
        <Text>
          You will receive information about joining the community within 20
          minutes.{" "}
          <strong style={{ fontWeight: "500" }}>
            {payment === "tg"
              ? "Please, confirm your account in telegram."
              : ""}
          </strong>
        </Text>
        <SecondTitle>Share your username on Instagram or LinkedIn</SecondTitle>
        <Text>
          Social media verification gives you a +70% chance of approval!
        </Text>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Text style={{ alignSelf: "center", width: "50%" }}>
            if you had a problem with payment, please, pay by this link:
          </Text>

          <Button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px 26px",
            }}
            onClick={handlePayment}
            $bg
          >
            Link
          </Button>
        </div>

        {setFormData ? (
          <SocialMediaInputs
            formData={formData}
            setFormData={setFormData}
            socialMediaArray={["instagram", "linkedin"]}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SocialMedia;
