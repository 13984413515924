import { FC } from "react";
import { useModal } from "../../../app/helpers/hooks/useModal";
import { StepProps } from "./model/types";

import styles from "./ui/index.module.css";

import Login from "../login/Login";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Input } from "../../../shared/input/Input";
import { Title } from "../../../shared/title/Title";
import { Text } from "../../../shared/Text/Text";
import { Linked } from "../../../shared/Linked/Linked";

const Step1: FC<StepProps> = ({
  formData,
  handleChange,
  placeholder,
  titles,
  name,
  errors,
}) => {
  const { isOpen, openModal, closeModal, ModalComponent } = useModal();
  return (
    <div className={styles.step2_wrapper}>
      <div className={styles.step1_titles}>
        <Title>Registration</Title>
        <Text>
        </Text>
        <Text style={{ display: "flex", flexWrap: "wrap", gap: "3px" }}>
          I already have an account.{" "}
          <Linked onClick={openModal} style={{ textDecoration: "underline" }}>
            Sign In
          </Linked>{" "}
          to my personal account
        </Text>
        <SecondTitle fz="22px" style={{ fontFamily: "Roboto Condensed" }}>
          {titles}
        </SecondTitle>
      </div>
      <div className={styles.inputs_wrapper}>
        <Input
          $error={errors?.email ? true : false}
          required
          placeholder={placeholder}
          value={formData.email}
          type="text"
          name={name}
          onChange={handleChange}
        />
        <Text style={{ color: "red", fontSize: "14px" }}>
          {errors?.email ? errors.email : ""}
        </Text>
      </div>
      <ModalComponent>
        <Login isOpen={isOpen} openModal={openModal} closeModal={closeModal} />
      </ModalComponent>
    </div>
  );
};

export default Step1;
