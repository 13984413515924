import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { ReactComponent as Camera } from "../../assets/icons/camera.svg";
import clsx from "./styles.module.css";
import { UsedListingPicture } from "../../pages/editAdvert/model/types";

interface Props {
  onImageChanger?: (data: File[]) => void;
  required?: boolean;
  images?: UsedListingPicture[];
}

const Uploader: FC<Props> = ({ onImageChanger, required, images = [] }) => {
  const [imageFiles, setImageFiles] = useState<File[]>([]);

  const handleImageUpload = (files: FileList) => {
    const newImageFiles = Array.from(files).slice(0, 8 - imageFiles.length);

    setImageFiles((prev) => [...prev, ...newImageFiles]);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      handleImageUpload(files);
    }
  };

  return (
    <div className={clsx.uploader_wrapper}>
      <div className={clsx.imageGallery}>
        {imageFiles.map((file, index) => (
          <img
            key={index}
            src={URL.createObjectURL(file)}
            alt={`Uploaded ${index + 1}`}
            className={clsx.imagePreview}
          />
        ))}
        {images.map((file, index) => (
          <img
            key={index + imageFiles.length}
            src={file.picture_url}
            alt={`Uploaded ${index + 1}`}
            className={clsx.imagePreview}
          />
        ))}
      </div>
      {imageFiles.length < 8 && (
        <div className={clsx.uploader}>
          <input
            required={required && imageFiles.length === 0}
            type="file"
            accept="image/*"
            onChange={handleInputChange}
            style={{ display: "none" }}
            id="fileInput"
            multiple
          />
          <label htmlFor="fileInput" className={clsx.uploadButton}>
            <Camera />
          </label>
        </div>
      )}
      {imageFiles.length >= 8 && (
        <p className={clsx.errorMessage}>You can only upload up to 8 photos.</p>
      )}
    </div>
  );
};

export default Uploader;
