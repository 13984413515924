import styled from "styled-components";
import { TitleProps } from "./model/types";

export const Title = styled.h3<TitleProps>`
  font-size: ${(props) => (props.$fz ? props.$fz : "72px")};
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: ${(props) => (props.mb ? props.mb : "")};
  margin-top: ${(props) => (props.mt ? props.mt : "")};
  line-height: ${(props) => (props.$lh ? props.$lh : "76px")};

  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 50px;
  }
`;
