import { Button } from "../../../shared/button/Button";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Text } from "../../../shared/Text/Text";
import { Title } from "../../../shared/title/Title";
import backIcon from "../../../assets/icons/arrow_toLeft.svg";

import styles from "../ui/index.module.css";
import { useNavigate } from "react-router-dom";

const Subscription = () => {
  const navigate = useNavigate();

  return (
    <div style={{ marginBottom: "50px" }} className={styles.right_side}>
      <button
        className={styles.back}
        onClick={() => navigate("/account/profile")}
      >
        <div>
          <img src={backIcon} alt="arrow to back" />
        </div>{" "}
        Back
      </button>
      <Title $fz="50px" style={{ marginBottom: "50px" }}>
        Subscription
      </Title>
      <div className={styles.card_wrapper}>
        <div className={styles.card_info}>
          <SecondTitle style={{ marginBottom: "12px" }}>
            Subscription is active
          </SecondTitle>
          <Text className={styles.card_info_text}>
            From July 01, 2025, it will be extended for a year for 75$.
          </Text>
          <SecondTitle className={styles.card_info_title}>
            Renewal with a bank card
          </SecondTitle>
          <Button $bg $icon>
            Change the card
          </Button>
        </div>
        <div className={styles.card_block}>
          <Text>...0000</Text>
          <Text>01/25</Text>
        </div>
      </div>
      <Button className={styles.subscription_btn} $icon $iconBgColor="#F5F6F6">
        Log out of the subscription
      </Button>
    </div>
  );
};

export default Subscription;
