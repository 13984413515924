import styled from "styled-components";

export const LessorLeft = styled.div`
  background-color: white;
  border-radius: 26px;
  padding: 20px 22px 20px 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  min-height: 110px;
  position: relative;
  z-index: 1;
  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
  }
`;
