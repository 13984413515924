import React, { FC, useEffect, useState } from "react";
import { StepProps } from "./model/types";
import clsx from "./ui/index.module.css";
import { Text } from "../../../shared/Text/Text";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Input } from "../../../shared/input/Input";
import { useGetUserInfoQuery } from "../../../app/redux/product/apiProducts";
import { Button } from "../../../shared/button/Button";
import { useVerifyAccountMutation } from "../../../app/redux/auth/authApi";
import { useNavigate, useSearchParams } from "react-router-dom";

const Step2: FC<StepProps> = ({
  name,
  name2,
  handleChange,
  formData,
  errors,
  successMessage,
  titles,
}) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [successVerify, setSuccessVerify] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { data, refetch } = useGetUserInfoQuery({});

  const token = localStorage.getItem("token");

  const [veryifyAccount] = useVerifyAccountMutation();
  const reg = JSON.parse(searchParams.get("reg") || "false");

  useEffect(() => {
    if (!data?.is_verified && data) {
      veryifyAccount({ data: { email: data?.email } });
      setSuccessVerify(!successMessage);
    }
  }, [data]);

  useEffect(() => {
    const step = localStorage.getItem("step");
    if (!token && step === "2") {
      navigate("/?step=true");
    }
  }, []);

  return (
    <div className={clsx.step1_wrapper}>
      <div className={clsx.step1_titles}>
        <SecondTitle fz="22px">{titles}</SecondTitle>
        <Text>
          Come up with a strong password consisting of letters, numbers
          and other symbols.
        </Text>
      </div>
      <div className={clsx.inputs_wrapper}>
        {!data?.is_verified && reg && !token?.length ? (
          <div className={clsx.inputs}>
            <>
              <Input
                required
                $error={errors?.password ? true : false}
                type={checked ? "text" : "password"}
                name={name}
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
              />
              <Text style={{ color: "red", fontSize: "14px" }}>
                {errors?.password ? errors.password : ""}
              </Text>
              <Input
                required
                $error={errors?.password_confirm ? true : false}
                type={checked ? "text" : "password"}
                name={name2}
                value={formData.password_confirm}
                onChange={handleChange}
                placeholder="Confirm"
              />
              <Text style={{ color: "red", fontSize: "14px" }}>
                {errors?.password_confirm ? errors.password_confirm : ""}
              </Text>
              <Text style={{ color: "red", fontSize: "14px" }}></Text>
            </>

            <Text style={{ color: "green", fontSize: "14px" }}>
              {successMessage || successVerify
                ? "We've sent a confirmation email to your inbox, please verify your account."
                : ""}
            </Text>
            <div className={clsx.check}>
              <input
                onChange={() => setChecked(!checked)}
                type="checkbox"
                name=""
                id="chekc"
              />
              <label htmlFor="chekc">Show password</label>
            </div>
          </div>
        ) : (
          <div>
            <Text>You need to verify your account before continueing</Text>
            <Button
              onClick={() => {
                veryifyAccount({ data: { email: data?.email } });
              }}
              $bg
              $icon
            >
              Verify
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step2;
