import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { dateFormatRegex } from "../constants/regex";
import { FlatData } from "../../pages/editAdvert/model/types";

dayjs.extend(customParseFormat);

export const formatDate = (
  data?: string | Date,
  formatString = "DD.MM.YYYY HH:mm"
): string => {
  if (!data) return "";

  const possibleFormats = [
    "DD.MM.YYYY HH:mm:ss",
    "DD.MM.YYYY HH:mm",
    "YYYY-MM-DDTHH:mm:ss.SSSSSSZZ",
    "YYYY-MM-DDTHH:mm:ss.SSSSSSZ",
    "YYYY-MM-DDTHH:mm:ssZZ",
    formatString,
  ];

  let parsedDate = dayjs("");

  if (typeof data === "string" && data.match(dateFormatRegex)) {
    parsedDate = dayjs(data, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
  } else {
    for (const format of possibleFormats) {
      parsedDate = dayjs(data, format);
      if (parsedDate.isValid()) {
        break;
      }
    }
  }

  return parsedDate.isValid() ? parsedDate.format("DD.MM.YYYY") : "";
};

export const assembleFlatData = (formatData: FlatData): FlatData => {
  return {
    description: formatData.description,
    date_from: formatData.date_from,
    date_to: formatData.date_to,
    country: formatData.country,
    city: formatData.city,
    price: formatData.price,
    comments: formatData.comments,
    is_rented: formatData.is_rented,
    created_at: formatData.created_at,
    updated_at: formatData.updated_at,
    message_ids: formatData.message_ids,
    channel_id: formatData.channel_id,
    status: formatData.status,
    district: formatData.district,
    swap: formatData.swap,
    room: formatData.room,
    flexible_dates: formatData.flexible_dates,
    listing_id: formatData.listing_id,
  };
};
