import { FC, useRef } from "react";
import {
  FormDataTypes,
  SocialPlatform,
} from "../../entities/profile/MyProfile/model/types";
import { Button } from "../button/Button";
import { SecondTitle } from "../secondTitle/SecondTitle";

import styles from "./ui/index.module.css";

interface socialMediaProps {
  formData: FormDataTypes;
  setFormData: (formData: React.SetStateAction<any>) => void;
  socialMediaArray: string[];
}

const SocialMediaInputs: FC<socialMediaProps> = ({
  formData,
  setFormData,
  socialMediaArray,
}) => {
  const inputRefs: Record<SocialPlatform, React.RefObject<HTMLInputElement>> = {
    instagram: useRef<HTMLInputElement>(null),
    linkedin: useRef<HTMLInputElement>(null),
    facebook: useRef<HTMLInputElement>(null),
    twitter: useRef<HTMLInputElement>(null),
    telegram: useRef<HTMLInputElement>(null),
    whatsapp: useRef<HTMLInputElement>(null),
  };

  const handleConnectClick = (platform: SocialPlatform) => {
    const value = inputRefs[platform].current?.value || "";
    setFormData?.((prevData: any) => ({
      ...prevData,
      [platform]: value,
    }));
  };

  const handleRemoveClick = (platform: SocialPlatform) => {
    setFormData?.((prevData: any) => ({
      ...prevData,
      [platform]: "",
    }));

    const inputRef = inputRefs[platform].current;
    if (inputRef) {
      inputRef.value = "";
    }
  };
  return (
    <>
      {socialMediaArray.map((platform) => {
        const typedPlatform = platform as SocialPlatform;

        return (
          <div key={typedPlatform} className={styles.one_input}>
            <SecondTitle fz="16px" className={styles.social_title}>
              {typedPlatform.charAt(0).toUpperCase() + typedPlatform.slice(1)}
            </SecondTitle>
            <input
              type="text"
              ref={inputRefs[typedPlatform]}
              defaultValue={formData[typedPlatform]}
              placeholder="Not added"
            />
            {formData[typedPlatform] ? (
              <Button
                $border
                $iconBgColor="black"
                onClick={(e) => {
                  e.preventDefault();
                  handleRemoveClick(typedPlatform);
                }}
              >
                Remove
              </Button>
            ) : (
              <Button
                $bg
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleConnectClick(typedPlatform);
                }}
              >
                Connect
              </Button>
            )}
          </div>
        );
      })}
    </>
  );
};

export default SocialMediaInputs;
