import { ReactNode, useEffect, useState } from "react";
import { Modal } from "../../../shared/modal/portal";

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const ModalComponent = ({ children }: { children: ReactNode }) => {
    if (!isOpen) return null;

    return (
      <Modal>
        <div
          className={`modal ${isOpen ? "modal_active" : ""}`}
          onClick={closeModal}
        >
          {children}
        </div>
      </Modal>
    );
  };

  return {
    isOpen,
    openModal,
    closeModal,
    ModalComponent,
  };
};
