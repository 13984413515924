import { FC } from "react";
import styled from "styled-components";
import { CarouselItemProps } from "./model/types";

const CarouselItemWrapper = styled.div``;

const CarouselItem: FC<CarouselItemProps> = ({ children, style }) => {
  return (
    <CarouselItemWrapper style={{ ...style }}>{children}</CarouselItemWrapper>
  );
};

export default CarouselItem;
