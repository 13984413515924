import styled from "styled-components";
import { SecondTitleProps } from "./model/types";

export const SecondTitle = styled.h5<SecondTitleProps>`
  font-family: "Roboto", sans-serif;
  font-weight: ${(props) => (props.weight ? props.weight : "500")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0")};
  font-size: ${(props) => (props.fz ? props.fz : "22px")};
  color: ${(props) => (props.color ? props.color : "#282828")};
`;
